<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{new Date().getFullYear()}} &copy;  Crest Realty.
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block">
                        Crest Realty with <i class="mdi mdi-heart text-danger"></i> by <a href="https://www.remaxcrest.ca/" target="_blank" class="text-reset"> Crest Realty</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>